<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="5">
        <el-select v-model="area_type"
                   class="width100"
                   @change="selectChange"
                   placeholder="请选择">
          <el-option label="全部区域"
                     value="ALL">
          </el-option>
          <el-option label="显示区域"
                     value="SHOW">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="19">
        <Address v-model="selectAddress"
                 :disabled="area_type === 'ALL'?true:false"></Address>
      </el-col>

    </el-row>
    <el-row class="mt-15">
      <el-col :span="6"
              class="flex">
        <el-input v-model="name"
                  clearable
                  placeholder="请输入广告名称"></el-input>
      </el-col>
      <el-col :span="6"
              class="ml-15">
        <el-button @click="reset">重置</el-button>
        <el-button type="primary"
                   @click="search">查询</el-button>
      </el-col>
    </el-row>
    <table-page dataName="advers"
                :tabList="tabList"
                ref="table"
                :searchParams="searchParams"
                tabStatus="time_status"
                :params="{type:$route.meta.type}"
                :request="fetchAdverts">
      <template slot="button">
        <el-button type="primary"
                   @click="addAdvert($route.meta.type)">添加广告</el-button>
      </template>
      <el-table-column label="活动图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="名称">
      </el-table-column>
      <el-table-column prop="sort"
                       show-overflow-tooltip
                       label="排序">
      </el-table-column>
      <el-table-column prop="url"
                       label="链接"
                       show-overflow-tooltip>
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          <span v-if="scope.row.started_at">{{scope.row.started_at}}-{{scope.row.ended_at}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.time_status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.time_status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-10"
                     @click="showDialog(scope)">查看数据</el-button>
          <el-button type="text"
                     @click="setAdvert($route.meta.type,scope)">编辑</el-button>
          <el-button type="text"
                     @click="delAdverts(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <el-dialog title="广告数据"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               :modal-append-to-body="false"
               width="50%">
      <el-row v-if="dialogData"
              type="flex"
              justify="center">
        <el-col :span="8"
                class="center">
          <div>曝光人数</div>
          <div class="num">{{dialogData.found_man_num | overNum}}</div>
        </el-col>
        <el-col :span="8"
                class="center">
          <div>点击量</div>
          <div class="num">{{dialogData.click_num | overNum}}</div>
        </el-col>
        <el-col :span="8"
                class="center">
          <div>点击人数</div>
          <div class="num">{{dialogData.click_man_num | overNum}}</div>
        </el-col>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TableImg from '@/components/TableImg'
import TablePage from '@/components/TablePage'
import Address from '@/components/Address'
import { fetchAdverts, delAdverts, fetchAdvertDetail } from '@/api/adverts'
export default {
  data () {
    return {
      name: '',
      area_type: 'ALL',
      selectAddress: {
        province: '',
        city: '',
        district: ''
      },
      dialogData: null,
      dialogVisible: false,
      fetchAdverts,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }],
      inputList: [{
        name: '活动ID',
        placeholder: '请输入活动ID',
        key: 'activity_id',
        value: ''
      }],
      searchParams: {},
      advertType: [{
        value: 'INDEX_BANNER',
        set: 'SetHomeCarousel'
      }, {
        value: 'INDEX_ACTIVITY',
        set: 'SetHomeActivity'
      }, {
        value: 'MERCHANTS_BANNER',
        set: 'SetMerchantBanner'
      }, {
        value: 'USER_BANNER',
        set: 'SetUserBanner'
      }]
    }
  },
  filters: {

    overNum (value) {
      if (!value) return '0'

      /* 原来用的是Number(value).toFixed(0)，这样取整时有问题，例如0.51取整之后为1，感谢Nils指正 */
      /* 后来改成了 Number(value)|0,但是输入超过十一位就为负数了，具体见评论 */
      var intPart = Number(value) - Number(value) % 1 // 获取整数部分（这里是windy93的方法）
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断

      var floatPart = '' // 预定义小数部分
      var value2Array = value.toString().split('.')

      //= 2表示数据有小数位
      if (value2Array.length === 2) {
        floatPart = value2Array[1].toString() // 拿到小数部分

        if (floatPart.length === 1) { // 补0,实际上用不着
          return intPartFormat + '.' + floatPart + '0'
        } else {
          return intPartFormat + '.' + floatPart
        }
      } else {
        return intPartFormat + floatPart
      }
    }
  },
  components: {
    TablePage,
    TableImg,
    Address
  },
  watch: {
    $route () {
      this.area_type = 'ALL'
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  methods: {
    async fetchAdvertDetail (item) {
      const res = await fetchAdvertDetail({
        id: item.id
      })
      if (res.meta.code === 0) {
        this.dialogData = res.data.data
      }
    },
    selectChange (val) {
      if (val === 'ALL') {
        this.selectAddress = {
          province: '',
          city: '',
          district: ''
        }
      }
    },
    search () {
      this.searchParams = {
        area_type: this.area_type,
        name: this.name,
        ...this.selectAddress
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    reset () {
      this.area_type = 'ALL'
      this.selectAddress = {
        province: '',
        city: '',
        district: ''
      }
      this.name = ''
    },
    showDialog (scope) {
      // this.dialogData = scope.row
      this.fetchAdvertDetail(scope.row)
      this.dialogVisible = true
    },
    addAdvert (name) {
      const advert = this.advertType.find(item => {
        return item.value === name
      })
      advert && this.$router.push({ name: advert.set })
    },
    advertData (name, scope) {
      const advert = this.advertType.find(item => {
        return item.value === name
      })
      advert && this.$router.push({ name: advert.data, query: { id: scope.row.id } })
    },
    setAdvert (name, scope) {
      const advert = this.advertType.find(item => {
        return item.value === name
      })
      advert && this.$router.push({ name: advert.set, query: { id: scope.row.id } })
    },
    delAdverts (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delAdverts({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}
.num {
  margin-top: 15px;
  font-size: 42px;
  font-weight: bold;
}
</style>
